$font-family: "Roboto Condensed",
Arial,
Helvetica,
sans-serif;

$global-section-large-title: 4.6rem;
$global-section-title: 3rem;
$global-section-desc: 1.8rem;
$global-grid-title: 2.4rem;
$global-grid-tag: 1.5rem;

$global-main-color: #10293F;
$global-grid-title-blue-color: #10293F;
$global-grid-desc-blue-color: #626A73;
$global-grid-img-preload-color: #c9ccd0;
$global-background-blue-color: #13314C;
$global-background-grey-color: #DFE2E7;
$global-red-color: #B7171E;
$global-dropdown-border-color: #0D2134;
$global-focus-color: rgba(52, 132, 203, 1);

$global-max-width: 1600px;
$global-width-tablet: 768px;
$global-width-mobile: 768px;
$global-section-leftright-space: 150px;

$assets_folder: "../assets";
