@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin transition($transition...) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    transition: $transition;
}

@mixin transform($transforms) {
    -webkit-transform: $transforms;
    -moz-transform: $transforms;
    -ms-transform: $transforms;
    transform: $transforms;
}

@mixin animation($animation...) {
    -o-animation: $animation;
    -moz-animation: $animation;
    -webkit-animation: $animation;
    -ms-animation: $animation;
    animation: $animation;
}

@mixin calc($calculate) {
    width: -webkit-calc($calculate);
    width: -moz-calc($calculate);
    width: calc($calculate);
}

@mixin break-point($alias, $dp) {
    @if(type-of($dp)==number) {
        @include break-point($alias, 'max-width' $dp) {
            @content;
        }
    }
    @else if(type-of($dp)==list) {
        @media (nth($dp, 1): nth($dp, 2)) {
            @if($alias !=null and $alias !="") {
                body:after {
                    content:$alias;
                    display: none;
                }
            }
            $media-type:$alias !global;
            @content;
            $media-type:'' !global;
        }
    }
}

@mixin linear-gradient($direction, $color-stops...) {
    @if is-direction($direction)==false {
        $color-stops: $direction, $color-stops;
        $direction: 180deg;
    }
    background: nth(nth($color-stops, 1), 1);
    background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
    background: linear-gradient($direction, $color-stops);
}

@function legacy-direction($value) {
    @if is-direction($value)==false {
        @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
    }
    $conversion-map: ( to top: bottom, to top right: bottom left, to right top: left bottom, to right: left, to bottom right: top left, to right bottom: left top, to bottom: top, to bottom left: top right, to left bottom: right top, to left: right, to left top: right bottom, to top left: bottom right);
    @if map-has-key($conversion-map, $value) {
        @return map-get($conversion-map, $value);
    }
    @return 90deg - $value;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }
    @include optional-at-root(':-moz-placeholder') {
        @content;
    }
    @include optional-at-root('::-moz-placeholder') {
        @content;
    }
    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}

@mixin add-bevel($color:#fff) {
    box-shadow: 0 1px 2px $color, 0 1px 1px #666, inset 0 -1px 1px rgba(0, 0, 0, 0.5), inset 0 4px 1px rgba(255, 255, 255, .2);
    -moz-box-shadow: 0 1px 2px $color, 0 1px 1px #666, inset 0 -1px 1px rgba(0, 0, 0, 0.5), inset 0 4px 1px rgba(255, 255, 255, .2);
    -webkit-box-shadow: 0 1px 2px $color, 0 1px 1px #666, inset 0 -1px 1px rgba(0, 0, 0, 0.5), inset 0 4px 1px rgba(255, 255, 255, .2);
    -webkit-box-shadow: 0 1px 2px $color, 0 1px 1px #666, inset 0 0px 1px rgba(0, 0, 0, .5), inset 0 4px 1px rgba(255, 255, 255, .2);
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
    @if $inset {
        -webkit-box-shadow: inset $top $left $blur $color;
        -moz-box-shadow: inset $top $left $blur $color;
        box-shadow: inset $top $left $blur $color;
    }
    @else {
        -webkit-box-shadow: $top $left $blur $color;
        -moz-box-shadow: $top $left $blur $color;
        box-shadow: $top $left $blur $color;
    }
}


// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@mixin for-size($range) {
    $phone-upper-boundary: 768px;
    $tablet-portrait-upper-boundary: 768px;
    $tablet-landscape-upper-boundary: 1600px;
    $desktop-upper-boundary: 1800px;
  
    @if $range == phone-only {
      @media (max-width: #{$phone-upper-boundary - 1}) { @content; }
    } @else if $range == tablet-portrait-up {
      @media (min-width: $phone-upper-boundary) { @content; }
    } @else if $range == tablet-landscape-up {
      @media (min-width: $tablet-portrait-upper-boundary) { @content; }
    } @else if $range == desktop-up {
      @media (min-width: $tablet-landscape-upper-boundary) { @content; }
    } @else if $range == big-desktop-up {
      @media (min-width: $desktop-upper-boundary) { @content; }
    }
  }